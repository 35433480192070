<template>
  <div style="height: 100vh;" class="login_bg">
    <v-row justify="center">
      <v-col
        :xl="3"
        :lg="4"
        :md="6"
        :sm="8"
        :cols="10"
      >
        <v-card
          class="mx-auto px-10 py-10 mt-6"
          style="background-color: rgb(255 255 255 / 90%);"
          outlined
        >
          <img
            class="logo mb-10"
            src="../assets/logo-havebike-new2019.png"
          >
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-text-field
                  v-model="form.username"
                  :rules="[v => !!v || __('Username is required')]"
                  :label="__('Username')"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-text-field
                  v-model="form.password"
                  :rules="[v => !!v || __('Password is required')]"
                  :label="__('Password')"
                  type="password"
                  outlined
                  required
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="pt-0 mt-0"
              >
                <v-checkbox
                  v-model="form.remember"
                  class="pt-0 mt-0"
                  label="Remember Me" />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-btn
                  color="primary"
                  large
                  block
                  @click="submitForm">
                  {{ __('Login') }}
                </v-btn>
                <a
                  style="text-decoration: none;"
                  class="mt-2 text-center d-block"
                  href="https://engine.havebike.co.uk/password/reset"
                >
                  <small>{{ __('Forgot Your Password?') }}</small>
                </a>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="showError"
      color="error"
    >
      {{ error }}
    </v-snackbar>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'LoginForm',

  data () {
    return {
      form: {
        remember: true
      },
      validForm: true,
      showError: false,
      error: null
    }
  },

  mounted () {
    axios.get(process.env.VUE_APP_API_BASE_URL)
  },

  methods: {
    submitForm () {
      if (this.$refs.form.validate()) {
        this.$auth.login(this.form)
          .catch(error => {
            this.showError = true
            this.form.password = null
            this.error = error.response.data.message
            this.$refs.form.resetValidation()
          })
      }
    }
  }
}
</script>
<style type="text/css">
  body {
    padding: 0;
  }
  .login_bg {
    background-image: url('https://havebike-cdn.s3.eu-west-2.amazonaws.com/assets/home_banner.jpg');
    background-size: cover;
  }
  .logo {
    width: 100%;
    margin: auto;
    display: block;
    max-width: 160px;
  }
</style>